import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { IconContext } from "react-icons";
import "./Firebase";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <IconContext.Provider
      value={{ color: "blue", className: "global-class-name" }}
    >
      <App />
    </IconContext.Provider>
  </React.StrictMode>
);
