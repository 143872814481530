import { useState, createContext } from "react";

type NavigationContextType = {
  navigationSection: string;
  changeNavigation: (section: string) => void;
};

const defaultContext = {
  navigationSection: "home",
  changeNavigation: () => {},
};

export const NavigationContext =
  createContext<NavigationContextType>(defaultContext);

export const NavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [navigation, setNavigation] = useState("home");

  const changeNavigation = (section: string) => {
    setNavigation(section);
  };
  return (
    <NavigationContext.Provider value={{ navigationSection: navigation , changeNavigation}}>
      {children}
    </NavigationContext.Provider>
  );
};
