import { AiOutlineMail } from "react-icons/ai";
import { useContext, useEffect, useRef } from "react";
import {
  FaChevronDown,
  FaGithub,
  FaResearchgate,
  FaXing,
} from "react-icons/fa";
import profilePicture from "../../assets/jan_stand.jpg";
import { NavigationContext } from "../../provider/NavigationContext";
import { scrollDown, scrollTop } from "../../utils/scroll";
import styles from "./Home.module.scss";
import SelectGroup from "../../components/select/SelectGroup";

const SocialLinks = [
  { icon: <FaGithub color="#000" />, link: "https://github.com/jvogt2306" },
  {
    icon: <FaXing color="#000" />,
    link: "https://www.xing.com/profile/Jan_Vogt18/cv",
  },
  {
    icon: <FaResearchgate color="#000" />,
    link: "https://www.researchgate.net/profile/Jan-Vogt/research",
  },
];

const work = [
  {
    station: "sipgate GmbH, Düsseldorf",
    details: `Aktuell arbeite ich bei sipgate, einem der ersten und führenden VoIP-Anbieter in Deutschland und Pionier in agilen Arbeitsmethoden. Mit meinem Team entwickle ich an einem Softphone names CLINQ, welches sich durch ein umwerfendes Design und providerunabhängige Telefonie auszeichnet. Unsere Vision ist es, das beste Softphone auf dem Markt zu werden, dafür setzen wir auf Design, Cloud-Infrastruktur und Machine Learning. Neben Sprachsynthese und -transkription sowie der Named-Entity-Recognition trainieren wir Modelle zur Vorhersage des Anrufverhaltens. Als Full-Stack-Dev befinde ich mich aktuell in einer Fokusgruppe, die sich auf die Entwicklung innovativer Features spezialisiert und diese mit den Kunden erprobt, wie z.B. aktuelle Trends auf ihre Machbarkeit zu überprüfen.`,
    open: true,
  },
  {
    station: "EASY SOFTWARE AG, Essen",
    details: `Die EASY SOFTWARE AG ist ein international agierender Konzern in Europa, Asien und den USA, welcher seit über 30 Jahren Kunden bei der Digitalisierung von Geschäftsprozessen unterstützt. Sie sind einer der führenden Anbieter für Dokumentenmanagementsysteme. Mein Team und ich arbeiteten am Restyling und Refactoring des Desktops- und des Web-Clients. Wir implementierten eine REST-API für das DMS-System, um Dokumente abzulegen und zu verwalten. Darüber hinaus konnte ich Pionierarbeiten leisten und war für die Planung und Durchführung von Proof of Concepts mit der Microsoft Hololens beauftragt.`,
  },
  {
    station: "Elmer Dienstleistung GmbH, Bottrop",
    details: `Die Elmer-Unternehmensgruppe besteht aus 13 rechtlich selbständigen Vertriebsgesellschaften und ist ein Fachgroßhändler in der Sanitär-Heizung-Klima-Branche. Innerhalb meiner Ausbildung durchlief ich die vollständige Prozesskette von der Beschaffung, der Logistik, dem Verkauf und der Buchhaltung und lernte deren Abläufe kennen. Nach meiner Ausbildung entwickelte und betreute ich eine Webanwendung für den Vertrieb, um erfasste Angebote gezielter nachzuverfolgen und zu steuern. Als Projektverantwortlicher für die Auswahl eines Dokumentenmanagementsystems konnte ich die Anforderungen an das System ermitteln und die Auswahl der Software durchführen. Als Key-User für das SAP-System war ich für die Schulung der Mitarbeiter und die Weiterentwicklung des Systems verantwortlich.`,
  },
];

const education = [
  {
    station: "Hochschule Ruhr West Bottrop",
    details: `Informatik, M. Sc. (2021) \nWirtschaftsinformatik, B. Sc. (2019)`,
  },
  {
    station: "Verwaltungs- und Wirtschafts-Akademie Essen",
    details: `IT-System-Ökonom (2016)`,
  },
  {
    station: `Berufskolleg für Wirtschaft und Verwaltung \nWirtschaftsgymnasium Gelsenkirchen`,
    details: `Informatikkaufmann (2014)`,
  },
  {
    station: `Berufskolleg der Stadt Bottrop `,
    details: `Informationstechnischer Assistent (2011) \nFachhochschulreife (2011)`,
  },
];

const science = [
  {
    topic: `EPEAI 2023`,
    title: `Energy Stocks Price Forecast and Sentiment Analysis based on Machine Learning Approaches – Which approach performs best in day trading?`,
    author: `Jan Vogt, Alexander Bönner, Michael Römmich, Malte Weiß, Merih Türkoglu`
  },
  {
    topic: `BLOCKCHAIN 2021`,
    title: `Decentralized Online Multiplayer Game Based on Blockchains`,
    author: `Raphael Burkert, Philipp Horwat, Rico Lütsch, Natalie Roth, Dennis Stamm, Fabian Stamm, Jan Vogt & Marc Jansen `,
    ref: "https://doi.org/10.1007/978-3-030-86162-9_5"
  },
  {
    topic: `Masterthesis`,
    title: `Vorhersage von Aktienkursbewegungen der Energiebranche mithilfe maschinellen Lernens und Stimmungserkennung von Beiträgen aus sozialen Medien`,
    author: `Jan Vogt`,
    ref: "https://repositorium.hs-ruhrwest.de/frontdoor/deliver/index/docId/741/file/Masterthesis_Vogt_Digitalversion.pdf"
  },
  {
    topic: `Bachelorthesis`,
    title: `Entwicklung und Evaluation einer generischen Dialogstruktur für Voicebots zur Vereinbarung von Terminen in der Kundenbetreuung`,
    author: `Jan Vogt`,
    ref: "https://repositorium.hs-ruhrwest.de/frontdoor/index/index/year/2020/docId/621"
  },
];

const freelance = [
  {
    title: "Du suchst?",
    content: `Neben meiner Festanstellung bin ich zusätzlich als Fullstack-Developer tätig. Du hast eine Idee und brauchst Hilfe bei der Umsetzung? Dein Team ist aktuell ausgelastet und du kommst mit deinen Aufträgen nicht mehr hinterher? Du suchst jemanden, der ein Proof-of-Concept baut und bewertet? Du benötigst Expertiese bei der Umsetzung von Machine-Learning-Algorithmen? Du suchst einen Seminarleiter der dein Personal mit passenden Content versorgt?
    
Dann bist du bei mir genau richtig! Während meines Masterstudiums und meiner Berufserfahrung konnte ich bereits eine Menge Projekte begleiten und in unterschiedliche Themen eintauchen.
    
Wenn ich dein Interesse geweckt habe, dann kontaktiere mich und wir werden uns sicherlich einig.`,
  },
];

const award = [
  {
    title: `European Finance Forum Award 2022`,
    content: `Preisträger - 1. Platz`,
    ref: "https://www.europeanfinanceforum.org/das-eff/eff-finance-award/"
  },
  {
    title: `Bester Masterabsolvent 2022`,
    content: `Jahrgangsbester Informatik M.Sc.`,
    ref: "https://www.hochschule-ruhr-west.de/studienabschlussfeier-fachbereich-1-1/"
  },
  {
    title: `Stipendium 2018`,
    content: `Firmenstipendium EASY SOFTWARE AG `,
    ref: ""
  },
  {
    title: `Stipendium 2017`,
    content: `Firmenstipendium EASY SOFTWARE AG `,
    ref: ""
  },
  {
    title: `Stipendium 2016`,
    content: `Firmenstipendium EASY SOFTWARE AG `,
    ref: ""
  },
];

export type Props = {
  navigation: string;
};

export const Home = () => {
  const { navigationSection, changeNavigation } = useContext(NavigationContext);

  const homeSectionRef = useRef<HTMLElement>(null);
  const workSectionRef = useRef<HTMLElement>(null);
  const educationSectionRef = useRef<HTMLElement>(null);
  const sienceSectionRef = useRef<HTMLElement>(null);
  const freelanceSectionRef = useRef<HTMLElement>(null);
  const awardSectionRef = useRef<HTMLElement>(null);
  const contactSectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    switch (navigationSection) {
      case "home":
        scrollDown(homeSectionRef);
        break;
      case "work":
        scrollDown(workSectionRef);
        break;
      case "education":
        scrollDown(educationSectionRef);
        break;
      case "science":
        scrollDown(sienceSectionRef);
        break;
      case "freelance":
        scrollDown(freelanceSectionRef);
        break;
      case "award":
        scrollDown(awardSectionRef);
        break;
      case "contact":
        scrollDown(contactSectionRef);
        break;
      default:
        scrollDown(homeSectionRef);
        break;
    }
  }, [navigationSection]);

  return (
    <div className={styles.page}>
      <section ref={homeSectionRef} className={styles.home}>
        <div className={styles.homeArea}>
          <img src={profilePicture} alt={"avatar"}></img>
          <div className={styles.content}>
            <div className={styles.greetingWrapper}>
              <span className={styles.title}>Moin!</span>
              <span className={styles.titleLarge}>Ich bin Jan.</span>
            </div>
            <div className={styles.properties}>
              <span className={styles.sub}>Fullstack-Developer</span>
              <span className={styles.sub}>Freelancer</span>
              <span className={styles.sub}>Informatiker (M.Sc.)</span>
              <span className={styles.sub}>Scientist</span>
            </div>
            <div className={styles.greetingWrapper}>
              <span className={styles.titleMedium}>
                Willkommen auf meiner Webseite!
              </span>
            </div>
            <div className={styles.socialLinks}>
              {SocialLinks.map((link, index) => (
                <a key={`social-link-${index}`} href={link.link}>
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.arrowDown}>
          <FaChevronDown
            color="black"
            onClick={() => scrollDown(workSectionRef)}
          />
        </div>
      </section>
      <section ref={workSectionRef} className={styles.dark}>
        <div className={styles.contentArea}>
          <div className={styles.title}>Work</div>
          <div className={styles.content}>
            {work.map((item, index) => {
              return (
                <SelectGroup
                  open={item.open ?? false}
                  color="dark"
                  key={`work-${index}`}
                  title={item.station}
                  content={item.details}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section ref={educationSectionRef} className={styles.light}>
        <div className={styles.contentArea}>
          <div className={styles.title}>Education</div>
          <div className={styles.content}>
            {education.map((item, index) => {
              return (
                <div key={`education-${index}`} className={styles.item}>
                  <p className={styles.header}>{item.station}</p>
                  <p className={styles.sub}>{item.details}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section ref={freelanceSectionRef} className={styles.dark}>
        <div className={styles.contentArea}>
          <div className={styles.title}>Freelance</div>
          <div className={styles.content}>
            {freelance.map((item, index) => {
              return (
                <div key={`freelance-${index}`} className={styles.item}>
                  <p className={styles.header}>{item.title}</p>
                  <span className={styles.sub}>{item.content}</span>
                </div>
              );
            })}
            <button onClick={() => changeNavigation("contact")}>Contact</button>
          </div>
        </div>
      </section>
      <section ref={awardSectionRef} className={styles.light}>
        <div className={styles.contentArea}>
          <div className={styles.title}>Award</div>
          <div className={styles.content}>
            {award.map((item, index) => {
              return (
                <div key={`award-${index}`} className={styles.item}>
                  <p className={styles.header}>{item.title}</p>
                  <span className={styles.sub}>{item.content}</span>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section ref={sienceSectionRef} className={styles.dark}>
        <div className={styles.contentArea}>
          <div className={styles.title}>Science</div>
          <div className={styles.content}>
            {science.map((item, index) => {
              return (
                <div key={`science-${index}`} className={styles.item}>
                  <p className={styles.topic}>{item.topic}</p>
                  <p className={styles.header}>{item.title}</p>
                  <p className={styles.sub}>{item.author}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section ref={contactSectionRef} className={styles.contact}>
        <div className={styles.contentArea}>
          <div className={styles.title}>Contact</div>
          <div className={styles.content}>
            <span className={styles.sub}>
              Du hast Fragen oder möchstest dich über Themen austauschen?
            </span>
            <span className={styles.sub}>
              Du suchst nach einem Softwareentwickler mit fachlicher Expertiese?
            </span>
            <span className={styles.sub}>Dann schreib mir per Mail!</span>
            <a
              href="mailto:contact@j-vogt.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button style={{ background: "#000"}}>
                <AiOutlineMail size={18} color="#fff" />
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
