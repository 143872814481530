export const scrollDown = (ref: any) => {
    window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
    });
};

export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

