import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import classes from "./SelectGroup.module.scss";

type Props = {
  title: string;
  content: string;
  color: "light" | "dark";
  open: boolean;
};

const SelectGroup = ({ title, content, color, open }: Props) => {
  const [isOpen, setisOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setisOpen(open);
    }
  }, [open]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.headline}>
        <p className={classes.header}>{title}</p>
        <FaChevronDown
          onClick={() => setisOpen(!isOpen)}
          className={isOpen ? classes.icon : classes.iconRotate}
          color={color === "light" ? "black" : "white"}
          size={24}
        />
      </div>
      {isOpen ? <span className={classes.sub}>{content}</span> : null}
    </div>
  );
};

export default SelectGroup;
