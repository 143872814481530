import { NavigationProvider } from "./provider/NavigationContext";
import { Home } from "./views/home/Home";
import { Navbar } from "./components/navbar/Navbar";
import { BrowserRouter, Outlet, Routes, Route } from "react-router-dom";
import { Footer } from "./components/footer/Footer";
import { Impressum } from "./views/impressum/Impressum";
import styles from "./App.module.scss";
const BRAND_NAME = "_JVOGT</>";

function App() {
  return (
    <BrowserRouter>
      <NavigationProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/impressum" element={<Impressum />} />
          </Route>
        </Routes>
      </NavigationProvider>
    </BrowserRouter>
  );
}

const Layout = () => {
  return (
     <div className={styles.appContainer}>
      <Navbar brand={BRAND_NAME} />
      <main>
        <Outlet />
      </main>
      <Footer/>
    </div>
  );
};

export default App;
