// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCBKbSe9YDsoGByIdG4IX2kOXqgIhXgcig",
    authDomain: "vogt-website.firebaseapp.com",
    projectId: "vogt-website",
    storageBucket: "vogt-website.appspot.com",
    messagingSenderId: "15256178288",
    appId: "1:15256178288:web:b642de17ddd94b22b62eb5",
    measurementId: "G-M0E8VZKSW4"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);




