import React, { useEffect } from "react";
import { scrollTop } from "../../utils/scroll";
import { Impressum as Content } from "./Impressum.content";
import styles from "./Impressum.module.scss";

export const Impressum = () => {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.content}>
      <h1>Impressum</h1>
        {Content.map((i, index) => (
          <div key={`${i.title}_${index}`}>
            <h3>{i.title}</h3>
            {i.content.map((c, subIndex) => (
              <p key={`${i.title}_${subIndex}`}>{c}</p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
