import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.scss";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.wrapper}>
      <p>{`© ${currentYear} - Jan Vogt`}</p>
      <p>|</p>
      <Link to="/impressum"> Impressum</Link>
    </div>
  );
};
