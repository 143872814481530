import { useContext, useRef, useState } from "react";
import { NavigationContext } from "../../provider/NavigationContext";
import styles from "./Navbar.module.scss";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../Firebase";
import { useLocation, useNavigate } from "react-router-dom";
type Props = {
  brand: string;
};

export const Navbar = ({ brand }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { changeNavigation } = useContext(NavigationContext);
  const navigate = useNavigate();
  const navRef = useRef<HTMLHeadElement | null>(null);
  const [prevScrollpos, setPrevScrollpos] = useState(window.scrollY);
  const location = useLocation();

  const handleSectionChange = (section: string) => {
    setIsOpen(false);
    if (location.pathname !== "/") {
      navigate("/");
    }

    //Animation Delay needed because ref position is not correct when page changed
    setTimeout(() => {
      changeNavigation(section);
    }, 100);
    logEvent(analytics, "navbar", {
      name: "clicked",
      value: section,
    });
  };

  window.onscroll = () => {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      if (navRef.current) {
        navRef.current.style.top = "0";
        setIsOpen(false);
      }
    } else {
      if (navRef.current && currentScrollPos > 100) {
        navRef.current.style.top = "-85px";
        setIsOpen(false);
      }
    }
    setPrevScrollpos(currentScrollPos);
  };

  return (
    <header ref={navRef}>
      <h1 className={styles.logo}>{brand}</h1>
      <button className={styles.navToggle} onClick={() => setIsOpen(!isOpen)}>
        <span />
      </button>
      <nav className={`${isOpen ? styles.visible : undefined}`}>
        <ul>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("home")}
            >
              Home
            </button>
          </li>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("work")}
            >
              Work
            </button>
          </li>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("education")}
            >
              Education
            </button>
          </li>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("freelance")}
            >
              Freelance
            </button>
          </li>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("award")}
            >
              Award
            </button>
          </li>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("science")}
            >
              Science
            </button>
          </li>
          <li className={styles.navItem}>
            <button
              className={styles.navLink}
              onClick={(_) => handleSectionChange("contact")}
            >
              Contact
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};
